@import "../../index.scss";

footer {
  display: flex;
  flex-direction: column;
  background: $blue-800;
  width: 100%;
  height: 8em !important;
  color: $white !important;
  font-size: 1.4em;
}

.footer-row {
  margin: auto !important;
  justify-content: space-between !important;
  width: 100%;
  max-width: 48em;
}

.copyright-container {
  width: 100% !important;
  text-align: center;
}

a {
  color: $white !important;
  font-size: 1.4em;
}

@include media-breakpoint-down(lg) {
  .footer-row-column {
    flex-basis: 0% !important;
  }
}
