@import "../../index.scss";

.modal-content {
  border-color: $orange-500 !important;
  border-width: 2px !important;
}

.modal-header {
  border-color: $orange-500 !important;
}

.login-modal-header-item {
  cursor: pointer;
  color: $gray-800 !important;
  font-size: 1.4em !important;
  font-weight: 400 !important;
}

.vr {
  background-color: $orange-500 !important;
}
