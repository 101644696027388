
input {
  width: 100%;
}

.page-header {
  text-align: center;
  border: 0;
  font-size: 1rem;
  caret-color: transparent;
}

@media (min-width: 576px) {
  .page-header {
    font-size: 2rem;
  }
}
