@import "../../index.scss";

#section-hero {
  height: 75vh !important;
  width: 75vw !important;
}

.hero-header {
  text-transform: uppercase;
  color: $gray-200 !important;
  line-height: 1em !important;
  font-weight: 600 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.hero-header-en {
  font-size: 3vw !important;
}

.hero-header-fi {
  font-size: 3vw !important;
}

.hero-bullet {
  text-transform: uppercase;
  line-height: 1.2em !important;
}

.hero-bullet-1 {
  color: $gray-600 !important;
}

.hero-bullet-1-en {
  font-size: 1.6vw !important;
}

.hero-bullet-1-fi {
  font-size: 2.05vw !important;
}

.hero-bullet-2-en {
  color: $gray-600 !important;
  font-weight: 600 !important;
}

.hero-bullet-2-en {
  font-size: calc(1.3vw + .25px) !important;
}

.hero-bullet-2-fi {
  font-size: .95vw !important;
}

.hero-bullet-3 {
  color: $orange-500 !important;
  font-weight: 600 !important;
}

.hero-bullet-3-en {
  font-size: calc(2.7vw + .5px) !important;
}

.hero-bullet-3-fi {
  font-size: 3.025vw !important;
}

.hero-bullet-4 {
  color: $gray-200 !important;
  font-weight: 600 !important;
}

.hero-bullet-4-en {
  font-size: 1.6vw !important;
}

.hero-bullet-4-fi {
  font-size: 1.2vw !important;
}

.hero-intro-paragraph {
  color: $gray-200 !important;
  font-size: calc(1vw + .5px) !important;
  line-height: 1.2em !important;
  margin-bottom: 1em !important;
}

.hero-intro-paragraph:last-child {
  margin-bottom: 0 !important;
}

.profile-title {
  margin: auto, 0;
}

.profile-pic {
  width: inherit;
}

.external-profile {
  margin: 0 !important;
  padding: .5rem;
  border-radius: 2rem;
  background-color: $gray-100;
}

.cv-section {
  height: 100vh;
  align-content: center;
  padding: 0 !important;
  margin: 0 !important;
}

.page-content {
  max-width: 2048px;
  margin: auto;
}

.contact-button {
  background-color: $orange-500 !important;
  border-style: none;
  border-radius: .25em;
  max-width: 14em;
  padding: .5em;
  color: $blue-800;
  text-transform: uppercase;
  font-size: 1.1em !important;
  font-weight: 700 !important;
}

@include media-breakpoint-down(md) {
  #section-hero {
    height: 75vh !important;
    width: 100vw !important;
    padding: 0 2em !important;
  }

  .hero-header {
    font-size: min(7vw, 7vh) !important;
  }

  .hero-bullet-1-en {
    font-size: min(3.8vw, 3.8vh) !important;
  }

  .hero-bullet-1-fi {
    font-size: min(4.8vw, 4.8vh) !important;
  }

  .hero-bullet-2-en {
    font-size: min(3.1vw, 3.1vh) !important;
  }

  .hero-bullet-2-fi {
    font-size: min(2.2vw, 2.2vh) !important;
  }

  .hero-bullet-3-en {
    font-size: min(6.5vw, 6.5vh) !important;
  }

  .hero-bullet-3-fi {
    font-size: min(7vw, 7vh) !important;
  }

  .hero-bullet-4-en {
    font-size: min(3.8vw, 3.8vh) !important;
  }

  .hero-bullet-4-fi {
    font-size: min(2.8vw, 2.8vh) !important;
  }

  .hero-intro-paragraph {
    font-size: min(3vw, 3vh) !important;
    margin-bottom: .5em !important;
  }

  .contact-button {
    font-size: min(3vw, 3vh) !important;
  }
}

@include media-breakpoint-down(sm) {
  .hero-bullets {
    padding-bottom: 2em !important;
  }
}
