@import "../../index.scss";

#section-work-experience {
  background-color: $blue-100;
  color: $blue-800;
  padding-bottom: 8em;
}

.section-work-experience-header {
  font-size: 4em;
  font-weight: 500;
  margin-bottom: 2em;
  padding-top: 2em;
  border-bottom-style: solid;
  border-bottom-color: $blue-800;
  border-bottom-width: .125em;
}

.work-experience-timeline-container {
  padding: 0 !important;
  margin: 0;
}

.work-experience-timeline-container {
  max-width: 18em !important;
}

.work-experience-timeline {
  width: 1em !important;
}

.work-experience-timeline-entry {
  height: 100%;
}

.work-experience-timeline-entry > line {
  stroke: $orange-600;
}

.work-experience-timeline-entry > circle {
  fill: $blue-800;
  radius: 10em;
}

.work-experience-timeline-entry > text {
  color: $blue-800;
  fill: $blue-800;
  font-weight: 600;
  font-size: 1.6em;
}

.work-experience-entry {
  overflow-x: hidden;
}

.work-experience-entry:last-child > div > svg > .work-experience-timeline-line-tail {
  display: none;
}

.work-experience-entry:first-child > div > svg > .work-experience-timeline-line-head {
  display: none;
}


.work-experience-entry-info {
  width: 32em !important;
  margin: 8em 0 !important;
}

.work-experience-entry-employer {
  max-width: 32em;
  font-size: 4em;
  font-weight: 500;
}

.work-experience-entry-title {
  max-width: 32em;
  font-size: 2em;
  margin-bottom: .5em;
}

.work-experience-entry-taglist {
  width: 100%;
  list-style: none;
  padding: 0 !important;
  float: left;
}

.work-experience-entry-taglist-item {
  font-size: 1.6em;
  opacity: .6;
  margin-right: 1em !important;
  float: left;
  min-width: 6em;
}

.work-experience-entry-taglist:last-child {
  margin-right: 0 !important;
}

.work-experience-entry-taglist-item:hover {
  cursor: default;
}

.work-experience-entry-techlist {
  width: 100%;
  list-style: none;
  padding: 0 !important;
  float: left;
  margin-bottom: 2em;
}

.work-experience-entry-techlist-item {
  border-style: solid;
  border-color: $blue-800;
  border-radius: 2em !important;
  border-width: .125em !important;
  padding: .25em .5em !important;
  margin: .25em !important;
  float: left;
  min-width: 6em;
  text-align: center;
  opacity: .7;
}

.work-experience-entry-techlist-item:hover {
  cursor: default;
}

.work-experience-entry-employer-summary {
  font-size: 1.6em;
  line-height: 1.4em;
  opacity: .8;
}

.work-experience-entry-started {
  opacity: 0;
}

.work-experience-entry-summary {
  font-size: 1.6em;
  line-height: 1.4em;
}

div.animate-in-timeline {
  opacity: 0;
  transform: translateX(100px);
}

div.show-in-timeline {
  animation-name: slide-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

text.show-in-timeline {
  animation-name: fade-in;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: .9;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: .9;
  }
}

.work-experience-entry-story-info {
  margin: 8em 0 !important;
}

.work-experience-entry-story-info > .work-experience-entry-taglist {
  margin: 1em 0 !important;
}

@include media-breakpoint-down(md) {
  .section-work-experience-header {
    font-size: 2em;
    margin-bottom: 1em;
    padding-top: 1em;
  }

  .work-experience-timeline-container {
    max-width: 25vw !important;
  }

  svg > * {
    scale: .33;
  }

  .work-experience-entry-info {
    max-width: 75vw !important;
    margin: 2.4em 0 !important;
  }

  .work-experience-entry-story-info {
    max-width: 75vw !important;
    margin: 2em 0 !important;
  }

  .work-experience-entry-employer {
    font-size: 1.2em;
  }

  .work-experience-entry-title {
    font-size: 1em;
  }

  .work-experience-entry-employer-summary {
    font-size: 1em;
  }

  .work-experience-entry-taglist-item {
    font-size: 1em;
  }

  .work-experience-entry-techlist-item {
    font-size: .8em;
    margin: .25em !important;
  }

  .work-experience-entry-summary {
    font-size: 1em;
  }
}

@include media-breakpoint-up(md) {
  .work-experience-timeline-container {
    max-width: 18em !important;
  }

}
