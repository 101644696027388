@import "../../index.scss";

.cookie-notification > .modal-dialog > .modal-content {
  border-style: none !important;
}

.cookie-notification-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.4em !important;
  font-weight: 500 !important;
  border-style: none !important;
  border-bottom-style: solid !important;
}

.cookie-notification-header-label {
  align-content: center;
}

.cookie-notification-header > .language-dropdown > a {
  font-size: 1.2em !important;
  color: $black !important;
  padding: 0 !important;
}

.cookie-notification-header > .language-dropdown > .language-dropdown-menu {
  margin-top: .5em !important;
}

.cookie-notification-header > .language-dropdown > .language-dropdown-menu > a {
  color: $black !important;
  padding: 0 .25em !important;
}

.cookie-notification-body {
  background-color: $blue-100 !important;
  font-size: 1.2em !important;
}

.cookie-notification-accept-button {
  background-color: $orange-500 !important;
  border-style: none !important;
  color: $black !important;
  font-size: 1.4em !important;
  font-weight: 500 !important;
}

.cookie-notification-read-privacy-policy {
  color: $black !important;
  font-size: 1.2em !important;
}
