.dashboard-nav {
  align-items: top !important;
}

@media (min-width: 576px) {
  .dashboard-nav {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .dashboard-navbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .dashboard-nav {
    align-items: start !important;
    flex-direction: column;
    position: fixed;
  }
}
