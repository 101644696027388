.row-filler {
  margin: 0;
  padding: 0;
  flex: 0;
}

@media (min-width: 1200px) {
  .row-content {
    flex: 2;
  }

  .row-filler {
    flex: 1;
  }
}
