@import "../../index.scss";

.login-button {
  background-color: $orange-500 !important;
  border-color: $orange-500 !important;
  margin-top: 1em;
  font-size: 1.4em !important;
  text-transform: uppercase;
}

.form-label {
  font-size: 1.2em;
  color: $gray-800 !important;
}
