@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

@import 'bootstrap/scss/bootstrap.scss';

a {
  color: $blue-800;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: $font-family-sans-serif;
  background: linear-gradient($blue-800, $blue-900);
  background-repeat: no-repeat;
  background-color: $blue-900;
  color: white;
}

header{
  color: black;
}

footer {
  color: black;
}

code {
  font-family: $font-family-monospace;
}
