@import "../../index.scss";

#section-showcase {
  width: 75vw !important;
  padding-bottom: 8em;
}

#section-showcase > h1 {
  float: left;
  margin: 1em;
}

#section-showcase > .showcase-tech-list {
  margin: 2.5em 0;
}

.showcase-project-list {
  clear: left;
}

.showcase-tech-filter {
  height: 6em;
  width: fit-content;
}

.showcase-title {
  font-size: 2em !important;
  margin-bottom: .25em !important;
  line-height: 1em;
}

.showcase-card-link-icon {
  width: .5em !important;
}

.showcase-card-link-icon > path {
  fill: white;
}

.showcase-card {
  padding: 0 !important;
  background: linear-gradient(45deg, $indigo-800, $indigo-900);
  margin: 1em;
  border-color: $orange-500 !important;
  border-style: groove;
  border-radius: 1em;
}

.showcase-video-player {
  aspect-ratio: 1280 / 720;
  border-radius: .75em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100% !important;
}

.showcase-figure {
  aspect-ratio: 1280 / 720;
  background-color: $indigo-800;
  border-radius: .75em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100% !important;
}

.showcase-video-info {
  text-align: left;
  margin: 0 !important;
  padding: 2em !important;
}

.showcase-tech-list {
  list-style: none;
  padding: 0 !important;
  float: left;
}

.showcase-tech-list-item {
  border-style: solid;
  border-color: $gray-400;
  border-radius: 2em !important;
  border-width: .125em !important;
  color: $gray-400;
  padding: .25em .5em !important;
  margin: .25em !important;
  float: left;
  min-width: 6em;
  text-align: center;
}

.showcase-tech-list-item:hover {
  cursor: default;
}

.showcase-brief {
  clear: left;
  font-size: 1.5em !important;
  color: $gray-500 !important;
  line-height: 1em !important;
}

@include media-breakpoint-down(md) {
  #section-showcase {
    width: 100vw !important;
    padding: 0 2em !important;
  }

  #section-showcase > h1 {
    margin: 1em 0;
    font-size: 2em !important;
  }

  #section-showcase > .showcase-tech-list {
    margin: 0 0 2em 0;
  }

  .showcase-title {
    font-size: 1.4em !important;
    line-height: 1.2em !important;
  }

  .showcase-card-link-icon {
    width: 1em !important;
  }

  .showcase-tech-list-item {
    font-size: .8em !important;
  }

  .showcase-brief {
    font-size: 1.2em !important;
  }
}

@media (max-width: 1350px) {
  .showcase-card {
    margin: 1em auto;
  }
}

@include media-breakpoint-up(md) {
  .showcase-card {
    min-width: 32em !important;
    max-width: 32em !important;
  }
}
