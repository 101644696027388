@import "../../index.scss";

a {
  text-decoration: none;
}

.float-end {
  float: right !important;
}

.featurette-divider {
  margin: 4em 0;
}

.carousel-item {
  height: 24em;
}

.toggable-accordion {
  width: 32em;
  margin-bottom: 2em;

}

@media (max-width: 576px) {
  .col {
    flex-basis: 100%;
  }
  .toggable-accordion {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .toggable-accordion {
    width: 21em;
  }
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-toggle {
  margin: 1.25rem 0;
}
