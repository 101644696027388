@import "../../index.scss";

.app-nav {
    align-items: flex-start !important;
}

nav .nav-link {
  color: $white;
}

.nav-link {
  height: 100%;
  align-content: center;
  margin: 0 !important;
  padding: 0 1em !important;
  font-size: 1.8em !important;
}

@include media-breakpoint-down(md) {
  .nav-link {
    padding: 0 !important;
    font-size: 1.6em !important;
  }
}

.navbar-brand > a {
  color: $white;
}

.brand-link {
  display: flex
}

.brand-logo {
  display: flex;
  width: 6rem !important;
  margin: auto;
  padding: 0 !important;
  background: radial-gradient($gray-100 50%, $blue-800);
  border-radius: 10rem;
  border-color: $blue-800;
}

.navbar-brand > a:hover {
  color: $blue-100;
}

.brand-text {
  color: $white;
  align-self: center;
  margin: .5em;
  font-size: 1.8em;
}

@include media-breakpoint-down(lg) {
  .brand-text {
    display: none !important;
  }
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-menu {
  min-width: fit-content !important;
}

.github-link {
  align-content: center;
  margin: .25em;
}

.github-link > img {
  width: 2.5em;
}
