@import "../../index.scss";

.language-dropdown {
  height: 100% !important;
  align-self: center;
  min-width: 2em !important;
  text-transform: uppercase;
  text-align: center;
}

.language-dropdown-menu {
  background-color: inherit !important;
  border-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.language-dropdown-menu-item {
  margin: 0;
  text-align: end !important;
  padding: 0 1em !important;
}

.language-dropdown-menu-item:hover {
  background-color: inherit !important;
}
